import request from "@/api";

export function login(data) {
    return request({
        url: '/admin/auth/login',
        method: 'post',
        data: data
    })
}
export function logout() {
    return request({
        url: '/admin/auth/logout',
        method: 'get'
    })
}

export function getAccountInfo() {
    return request({
        url: '/admin/auth/account-info',
        method: 'get'
    })
}

export function getAccountPower(){
    return request({
        url: '/admin/auth/account-power'
    })
}