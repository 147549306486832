import { login,logout,getAccountInfo,getAccountPower} from "@/api/auth"
import storage from "@/util/storage"

const user = {
    state: {
        token: '',
        account: '',
        power: ''
    },
    getters:{   
        ISLOGIN(state){
            return state.token !== '' 
            && state.account !== ''
        },
        GETMENULIST(state){
            return state.power.menus
        }
    },
    mutations: {
        SAVE_ACCOUNT(state, account){
            state.account = account
        },
        SAVE_TOKEN(state, token){
            state.token = token
        },
        SAVE_POWER(state, power){
            state.power = power
        }
    },
    actions: {
        LOGIN({commit}, user){
            return new Promise(function(resolve){
                login(user).then(res => {
                    commit("SAVE_TOKEN", res.data.data)
                    storage.saveSessionString("token", res.data.data)
                    getAccountInfo().then(re => {
                        commit("SAVE_ACCOUNT", re.data.data)
                        storage.saveSessionObject("account", re.data.data)
                    })

                    getAccountPower().then(re => {
                        commit("SAVE_POWER", re.data.data)
                        storage.saveSessionObject("power", re.data.data)
                        console.log(re)
                    })

                    resolve(res)
                })
            })
        },
        
        LOGOUT({commit}){
            return new Promise(function(resolve){
                logout().then(res => {
                    commit("SAVE_TOKEN", '')
                    storage.remove("token")
                    commit("SAVE_ACCOUNT", '')
                    storage.remove("account")
                    commit("SAVE_POWER", '')
                    storage.remove("power")

                    resolve(res)
                })
            })
        },

        RECOVERY_ACCOUNT({commit}){
            let account = storage.getSessionObject("account")
            let token = storage.getSessionString("token")
            let power = storage.getSessionString("power")
            if(account){
                commit("SAVE_TOKEN", token)
                commit("SAVE_ACCOUNT", account)
                commit("SAVE_POWER", JSON.parse(power))
            }
        }
    }
}

export default user