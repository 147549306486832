import store from '@/store';
import axios from 'axios'
import router from '../router'

// 创建axios实例
const request = axios.create({
    //baseURL: 'http://localhost:9509',
    baseURL: '/api',
    // 超时
    timeout: 10000,
    headers: {'Content-Type': 'application/json;charset=utf-8'}
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (store.state.user.token) {
      //'Bearer '
      config.headers['token'] = store.state.user.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

request.interceptors.response.use(function (res) {
  if(res.status === 200) {
    console.log(res.data.code)
    if(res.data.code === 401){
      router.push({name: "login"})
    }else{
      return Promise.resolve(res)
    }
  } else {
    return Promise.reject(res)
  }
}, function (error) {
  return Promise.reject(error);
})

export default request