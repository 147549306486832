<template>
  <router-view/>
</template>

<script>
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "hello",
	// mounted() {
	// 	this.$router.push({name: "login"})
	// }
}
</script>

<style>

</style>