import store from '@/store'
import storage from '@/util/storage'
import {
    createRouter,
    createWebHistory
} from 'vue-router'

/**
 * 定义路由信息
 * 
 */
 const routes = [{
    name: 'login',
    path: '/login',
    component: () => import('@/views/login'),
}, {
    name: '首页',
    alias: '/',
    path: '/main',
    component: () => import('@/views/main'),
    children: [
        {
            name: '面板',
            alias: '/',
            path: '/dashboard',
            component: () => import('@/views/system/dashboard'),
        },
        { 
            name: '账号管理',
            alias: '/',
            path: '/account',
            // component: () => import('@/views/system/account'),
            children: [
                { 
                    name: '账号列表',
                    alias: '/',
                    path: 'account-list',
                    component: () => import('@/views/system/account/account-index'),
                },
                { 
                    name: '角色列表',
                    alias: '/',
                    path: 'role-list',
                    component: () => import('@/views/system/account/role-index'),
                },
                { 
                    name: '权限列表',
                    alias: '/',
                    path: 'permission-list',
                    component: () => import('@/views/system/account/permission-index'),
                }
            ]
        },
        { 
            name: '用户管理',
            alias: '/',
            path: '/user',
            // component: () => import('@/views/system/account'),
            children: [
                { 
                    name: '用户列表',
                    alias: '/',
                    path: 'user-list',
                    component: () => import('@/views/system/user/user-index'),
                },
                { 
                    name: '患者列表',
                    alias: '/',
                    path: 'patient-list',
                    component: () => import('@/views/system/user/patient-index'),
                }
            ]
        },
        { 
            name: '新闻管理',
            alias: '/',
            path: '/news',
            // component: () => import('@/views/system/account'),
            children: [
                { 
                    name: '新闻列表',
                    alias: '/',
                    path: 'news-list',
                    component: () => import('@/views/system/news/news-index'),
                }
            ]
        },
        { 
            name: '医生管理',
            alias: '/',
            path: '/doctor',
            // component: () => import('@/views/system/account'),
            children: [
                { 
                    name: '医生申请列表',
                    alias: '/',
                    path: 'apply-list',
                    component: () => import('@/views/system/doctor/apply-index'),
                },
                { 
                    name: '医生列表',
                    alias: '/',
                    path: 'doctor-list',
                    component: () => import('@/views/system/doctor/doctor-index'),
                },
                { 
                    name: '医院列表',
                    alias: '/',
                    path: 'hospital-list',
                    component: () => import('@/views/system/doctor/hospital-index'),
                }
            ]
        },
        { 
            name: '订单管理',
            alias: '/',
            path: '/order',
            // component: () => import('@/views/system/account'),
            children: [
                { 
                    name: '订单列表',
                    alias: '/',
                    path: 'order-list',
                    component: () => import('@/views/system/order/order-index'),
                },
                { 
                    name: '退款列表',
                    alias: '/',
                    path: 'refund-list',
                    component: () => import('@/views/system/order/refund-index'),
                }
            ]
        },
        { 
            name: '问诊管理',
            alias: '/',
            path: '/case',
            // component: () => import('@/views/system/account'),
            children: [
                { 
                    name: '问诊列表',
                    alias: '/',
                    path: 'case-list',
                    component: () => import('@/views/system/case/case-index'),
                }
            ]
        }
    ]
}]

const router = createRouter({
    history: createWebHistory(),
    routes, 
})

router.beforeEach((to) => {
    if(to.name == 'login') return true

    if(!store.getters.ISLOGIN){
        if(storage.getSessionString("token")){
            store.dispatch("RECOVERY_ACCOUNT")
        }else{
            router.push({name: "login"})
        }
    }
})

export default router